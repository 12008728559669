import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';

const buyLinks = {
  hobby: {
    month: `https://buy.stripe.com/3cs03veyO5Pnc3C3ce`,
    year: `https://buy.stripe.com/cN23fHgGW1z74BaaEH`,
  },
  pro: {
    month: `https://buy.stripe.com/dR617z76m91zgjSaEI`,
    year: `https://buy.stripe.com/3cseYpeyOb9H7Nm9AF`,
  },
};

const Feature = props => {
  return (
    <Flex align="center">
      <Flex shrink={0}>
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color="brand.500"
          _dark={{ color: 'brand.300' }}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          ></path>
        </Icon>
      </Flex>
      <Box ml={4}>
        <Text mt={2} color="gray.700" _dark={{ color: 'gray.400' }}>
          {props.children}
        </Text>
      </Box>
    </Flex>
  );
};

const PricingTier = ({ onOpen, onOpenSub }) => {
  const [frequency, setFrequency] = useState('month');
  return (
    <Box py="64px" bg="gray.100" _dark={{ bg: 'gray.700' }}>
      <Box w="full" px={[10, , 4]} mx="auto" textAlign="center">
        <Text
          mb={2}
          fontSize="5xl"
          fontWeight="bold"
          fontFamily="Inter"
          lineHeight="tight"
        >
          Pricing
        </Text>
        <Text
          mb={6}
          fontSize={['lg', , 'xl']}
          color="gray.600"
          _dark={{ color: 'gray.400' }}
          fontWeight="semibold"
          fontFamily="Inter"
        >
          Try out the free tier or Subscribe for full access to all features
        </Text>
        <Flex justify="center" mx={['auto', 0]} mb={-2}>
          <Stack
            direction="row"
            justify="space-between"
            p="2"
            textAlign="center"
            rounded="md"
            bg="gray.200"
            _dark={{ bg: 'gray.600' }}
            spacing={0}
          >
            <Button
              colorScheme="blue"
              variant={frequency === 'month' ? 'solid' : 'outline'}
              onClick={() => setFrequency('month')}
              px={6}
              roundedRight="none"
              fontWeight="semibold"
              fontFamily="Inter"
            >
              Bill Monthly
            </Button>
            <Stack spacing={0}>
              <Button
                colorScheme="blue"
                variant={frequency === 'year' ? 'solid' : 'outline'}
                onClick={() => setFrequency('year')}
                px={6}
                roundedLeft="none"
                fontWeight="semibold"
                fontFamily="Inter"
              >
                Bill Yearly
              </Button>
              <Flex justify="right" mr={3} mb={-6}>
                <Tag
                  colorScheme="green"
                  borderTopRadius="none"
                  w="5rem"
                  fontSize={{ base: 'xs', sm: 'sm' }}
                  textAlign="center"
                >
                  <Flex w="fit-content" mx="auto" fontWeight="bold">
                    {'Save 20%'}
                  </Flex>
                </Tag>
              </Flex>
            </Stack>
          </Stack>
        </Flex>
      </Box>
      <Box maxW="7xl" py="20" px={{ base: 0, md: 6 }} mx="auto">
        <SimpleGrid columns={[1, , , 3]} gap={[16, 8]} p={0}>
          <Box
            rounded={['none', 'lg']}
            shadow={['none', 'md']}
            bg="white"
            _dark={{ bg: 'gray.800' }}
          >
            <Flex
              direction="column"
              justify="space-between"
              p="6"
              borderBottomWidth="1px"
              color="gray.200"
              _dark={{ color: 'gray.600' }}
            >
              <Text
                mb={1}
                fontSize="lg"
                fontWeight="semibold"
                fontFamily="Inter"
                color="gray.700"
                _dark={{ color: 'gray.400' }}
              >
                Free
              </Text>
              <Flex
                mb={2}
                fontSize="5xl"
                fontWeight={['bold', 'extrabold']}
                fontFamily="Inter"
                color="gray.900"
                _dark={{ color: 'gray.50' }}
                lineHeight="tight"
              >
                ${frequency === 'month' ? 0 : 0}
                <Text
                  fontSize="2xl"
                  fontWeight="medium"
                  color="gray.600"
                  _dark={{ color: 'gray.400' }}
                  mt={6}
                >
                  {' '}
                  /{frequency}
                </Text>
              </Flex>
              <Link
                w={['full', , 'auto']}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={5}
                py={3}
                border="solid transparent"
                fontWeight="bold"
                fontFamily="Inter"
                rounded="xl"
                shadow="md"
                _light={{ color: 'white' }}
                bg="blue.600"
                _dark={{ color: 'white', bg: 'blue.500' }}
                _hover={{
                  bg: 'blue.400',
                  _dark: { bg: 'blue.700' },
                }}
                onClick={onOpen}
              >
                Get the App
              </Link>
            </Flex>
            <Stack direction="column" p="6" spacing="3" flexGrow="1">
              <Feature>Access to Semble Product Database (+8K items)</Feature>
              <Feature>Track up to 50 products</Feature>
              <Feature>Private inventory</Feature>
              <Feature>Access to Semble Creative Community</Feature>
            </Stack>
          </Box>

          <Box
            rounded={['none', 'lg']}
            shadow={['none', 'md']}
            bg="white"
            _dark={{ bg: 'gray.800' }}
          >
            <Flex
              direction="column"
              justify="space-between"
              p="6"
              borderBottomWidth="1px"
              color="gray.200"
              _dark={{ color: 'gray.600' }}
            >
              <Text
                mb={1}
                fontSize="lg"
                fontWeight="semibold"
                fontFamily="Inter"
                color="gray.700"
                _dark={{ color: 'gray.400' }}
              >
                Hobby
              </Text>
              <Flex
                mb={2}
                fontSize="5xl"
                fontWeight={['bold', 'extrabold']}
                fontFamily="Inter"
                color="gray.900"
                _dark={{ color: 'gray.50' }}
                lineHeight="tight"
              >
                ${frequency === 'month' ? `3.00` : `30.00`}
                <Text
                  fontSize="2xl"
                  fontWeight="medium"
                  color="gray.600"
                  _dark={{ color: 'gray.400' }}
                  mt={6}
                >
                  {' '}
                  /{frequency}
                </Text>
              </Flex>
              <Link
                w={['full', , 'auto']}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={5}
                py={3}
                border="solid transparent"
                fontWeight="bold"
                fontFamily="Inter"
                rounded="xl"
                shadow="md"
                _light={{ color: 'white' }}
                bg="green.600"
                _dark={{ color: 'white', bg: 'green.500' }}
                _hover={{
                  bg: 'green.400',
                  _dark: { bg: 'green.700' },
                }}
                onClick={onOpenSub}
                // href={buyLinks.hobby[frequency]}
              >
                Get Semble Hobby
              </Link>
            </Flex>
            <Stack direction="column" p="6" spacing="3" flexGrow="1">
              <Feature>Access to Semble Product Database (+8K items)</Feature>
              <Feature>Track 100 products</Feature>
              <Feature>Private + Public inventory</Feature>
              <Feature>Access to Semble Creative Community</Feature>
              <Feature>Inventory syncs across devices</Feature>
              <Feature>Add tags/notes to tracking products</Feature>
            </Stack>
          </Box>

          <Box
            rounded={['none', 'lg']}
            shadow={['none', 'md']}
            bg="white"
            _dark={{ bg: 'gray.800' }}
          >
            <Flex
              direction="column"
              justify="space-between"
              p="6"
              borderBottomWidth="1px"
              color="gray.200"
              _dark={{ color: 'gray.600' }}
            >
              <Text
                mb={1}
                fontSize="lg"
                fontWeight="semibold"
                fontFamily="Inter"
                color="gray.700"
                _dark={{ color: 'gray.400' }}
              >
                Professional
              </Text>
              <Flex
                mb={2}
                fontSize="5xl"
                fontWeight={['bold', 'extrabold']}
                fontFamily="Inter"
                color="gray.900"
                _dark={{ color: 'gray.50' }}
                lineHeight="tight"
              >
                ${frequency === 'month' ? `5.00` : `50.00`}
                <Text
                  fontSize="2xl"
                  fontWeight="medium"
                  color="gray.600"
                  _dark={{ color: 'gray.400' }}
                  mt={6}
                >
                  {' '}
                  /{frequency}
                </Text>
              </Flex>
              <Link
                w={['full', , 'auto']}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={5}
                py={3}
                border="solid transparent"
                fontWeight="bold"
                fontFamily="Inter"
                rounded="xl"
                shadow="md"
                _light={{ color: 'white' }}
                bg="green.600"
                _dark={{ color: 'white', bg: 'green.500' }}
                _hover={{
                  bg: 'green.400',
                  _dark: { bg: 'green.700' },
                }}
                onClick={onOpenSub}
                // href={buyLinks.pro[frequency]}
              >
                Get Semble Pro
              </Link>
            </Flex>
            <Stack direction="column" p="6" spacing="3" flexGrow="1">
              <Feature>Access to Semble Product Database (+8K items)</Feature>
              <Feature>Track unlimited products</Feature>
              <Feature>Private + Public inventory</Feature>
              <Feature>Access to Semble Creative Community</Feature>
              <Feature>Inventory syncs across devices</Feature>
              <Feature>Add tags/notes to tracking products</Feature>
              <Feature>Create custom trackable products</Feature>
            </Stack>
          </Box>
        </SimpleGrid>
      </Box>
      {/* <Box w="full" px={[10, , 4]} mx="auto" textAlign="center">
        <Text mb={2} fontSize="5xl" fontWeight="bold" lineHeight="tight">
          Semble Pricing
        </Text>
        <Text
          mb={6}
          fontSize={['lg', , 'xl']}
          color="gray.600"
          _dark={{ color: 'gray.400' }}
        >
          Try out the free tier or Subscribe for full access and additional
          features
        </Text>
        <Flex justify="center" mx={['auto', 0]} mb={-2}>
          <Stack
            direction="row"
            justify="space-between"
            p="2"
            textAlign="center"
            rounded="md"
            bg="gray.200"
            _dark={{ bg: 'gray.600' }}
            spacing={0}
          >
            <Button
              colorScheme="blue"
              variant={frequency === 'month' ? 'solid' : 'outline'}
              onClick={() => setFrequency('month')}
              px={6}
              roundedRight="none"
            >
              Bill Monthly
            </Button>
            <Stack spacing={0}>
              <Button
                colorScheme="blue"
                variant={frequency === 'year' ? 'solid' : 'outline'}
                onClick={() => setFrequency('year')}
                px={6}
                roundedLeft="none"
              >
                Bill Yearly
              </Button>
              <Flex justify="right" mx={['auto', 0]} mb={-6}>
                <Tag colorScheme="green" borderTopRadius="none" ml={20} mr={5}>
                  {'Save 2 Months'}
                </Tag>
              </Flex>
            </Stack>
          </Stack>
        </Flex>
      </Box> */}
    </Box>
  );
};

export default PricingTier;
