import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Box,
  Button,
} from '@chakra-ui/react';
import {
  IoAnalyticsSharp,
  IoLogoBitcoin,
  IoSearchSharp,
} from 'react-icons/io5';
import { ReactElement } from 'react';

const featureText = {
  aa: `Discover thousands of art and crafting products in our intuitive database.
  From hobbyists to professionals, Semble saves you time by simplifying supply tracking.
  Empower your artistic vision with easy access and seamless organization.`,
  aa2: `Effortlessly manage your creative supplies with our intuitive platform. 
  Find and categorize thousands of art and crafting products in our robust database. 
  Whether you're a professional artist or a hobbyist, Semble streamlines your creative process, saving you valuable time. 
  Access and track your supplies with just a few clicks, empowering you to bring your artistic vision to life.`,
  bb: `Elevate your artistic ambiance with Semble's Dark Mode. 
  Immerse yourself in a visually pleasing experience that reduces eye strain during late-night creative sessions. 
  Embrace the elegant dark-themed interface that enhances your artistic inspiration and optimizes inventory management visibility.`,
  bb2: `Elevate your artistic ambiance with Semble's Dark Mode. 
  Immerse yourself in a visually pleasing experience that reduces eye strain during late-night creative sessions. 
  Embrace the elegant dark-themed interface that enhances your artistic inspiration and optimizes inventory management visibility.`,
};

export default function FeatureImage1() {
  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid
        alignItems="start"
        columns={{
          base: 1,
          md: 2,
        }}
        mb={24}
        spacingY={{
          base: 10,
          md: 32,
        }}
        spacingX={{
          base: 10,
          md: 24,
        }}
      >
        <Box>
          <Text
            mb={4}
            fontSize={{
              base: '3xl',
              md: '4xl',
            }}
            fontWeight="bold"
            fontFamily="Inter"
            letterSpacing="tight"
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            color="gray.900"
            _dark={{
              color: 'gray.400',
            }}
            lineHeight={{
              md: 'shorter',
            }}
          >
            Simplify Your Creative Journey with Seamless Organization
          </Text>
          <Text
            mb={5}
            textAlign={{
              base: 'center',
              sm: 'left',
            }}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
            fontSize={{
              base: 'md',
              md: 'xl',
            }}
            fontWeight="semibold"
          >
            {featureText['aa']}
          </Text>
          {/* <Button
            w={{
              base: 'full',
              sm: 'auto',
            }}
            size="lg"
            bg="gray.900"
            _dark={{
              bg: 'gray.700',
            }}
            _hover={{
              bg: 'gray.700',
              _dark: {
                bg: 'gray.600',
              },
            }}
            color="gray.100"
            as="a"
          >
            Learn More
          </Button> */}
        </Box>
        <Box
          w="full"
          h="full"
          bg="gray.200"
          _dark={{
            bg: 'gray.700',
          }}
        >
          {/* IMAGE */}
          <Image src={'/mockup2.png'} />
        </Box>
      </SimpleGrid>
      <SimpleGrid
        alignItems="center"
        columns={{
          base: 1,
          md: 2,
        }}
        flexDirection="column-reverse"
        mb={24}
        spacingY={{
          base: 10,
          md: 32,
        }}
        spacingX={{
          base: 10,
          md: 24,
        }}
      >
        <Box
          order={{
            base: 'initial',
            md: 2,
          }}
        >
          <Text
            mb={4}
            fontSize={{
              base: '3xl',
              md: '4xl',
            }}
            fontWeight="bold"
            fontFamily="Inter"
            letterSpacing="tight"
            textAlign={{
              base: 'center',
              md: 'left',
            }}
            color="gray.900"
            _dark={{
              color: 'gray.400',
            }}
            lineHeight={{
              md: 'shorter',
            }}
          >
            Your Creativity in a Different Tone
          </Text>
          <Text
            mb={5}
            fontWeight="semibold"
            fontFamily="Inter"
            textAlign={{
              base: 'center',
              sm: 'left',
            }}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
            fontSize={{
              base: 'md',
              md: 'xl',
            }}
          >
            {featureText['bb']}
          </Text>
          <Text
            mb={5}
            textAlign={{
              base: 'center',
              sm: 'left',
            }}
            color="gray.600"
            _dark={{
              color: 'gray.400',
            }}
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
          >
            {`In art and design, tone refers to how light or dark something is. Neat, huh?`}
          </Text>
          {/* <Button
            w={{
              base: 'full',
              sm: 'auto',
            }}
            size="lg"
            bg="gray.900"
            _dark={{
              bg: 'gray.700',
            }}
            _hover={{
              bg: 'gray.700',
              _dark: {
                bg: 'gray.600',
              },
            }}
            color="gray.100"
            as="a"
          >
            Learn More
          </Button> */}
        </Box>
        <Box
          w="full"
          h="full"
          bg="gray.200"
          _dark={{
            bg: 'gray.700',
          }}
        >
          {/* IMAGE */}
          <Image src={'/mockup3.png'} />
        </Box>
      </SimpleGrid>
    </Container>
  );
}
