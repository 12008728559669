import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';

export default function SmallFooterWithNavigation() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      w="full"
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Stack direction={'row'} spacing={6}>
          <Link
            href={'#'}
            fontWeight="normal"
            fontFamily="Inter"
            textDecoration="underline"
          >
            Semble
          </Link>
          <Link
            href={'#features'}
            fontWeight="normal"
            fontFamily="Inter"
            textDecoration="underline"
          >
            Features
          </Link>
          <Link
            href={'#pricing'}
            fontWeight="normal"
            fontFamily="Inter"
            textDecoration="underline"
          >
            Pricing
          </Link>
          <Link
            href={'https://help.semble.site/docs/contact-us'}
            isExternal
            fontWeight="normal"
            fontFamily="Inter"
            textDecoration="underline"
          >
            Contact
          </Link>
        </Stack>
        <Text fontSize="sm" fontFamily="Inter" color="gray.500">
          © 2023 Semble App. All rights reserved
        </Text>
      </Container>
    </Box>
  );
}
