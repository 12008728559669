import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Container,
  useDisclosure,
} from '@chakra-ui/react';

import CallToActionWithVideo from './components/Hero';
import FeatureCards from './components/FeatureCards';
import PricingTier from './components/PricingTiers';
import FeatureImage1 from './components/FeatureImage1';
import SmallFooterWithNavigation from './components/Footer';
import WithSubnavigation from './components/Header';
import SFCCTA from './components/SFCCTA';

export default function Lander() {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  const isOpen = false;

  const onOpen = () => (window.location = 'https://app.semble.site/');
  const onOpenSub = () => (window.location = 'https://app.semble.site/');

  const onOpenDemoVid = () =>
    (window.location = 'https://twitter.com/i/status/1699174659801989168');

  const onClose = () => '';

  return (
    <VStack p={0}>
      <WithSubnavigation />
      <CallToActionWithVideo onOpen={onOpenDemoVid} />
      <FeatureImage1 />
      <Container maxW="full" id="features">
        <FeatureCards />
      </Container>
      <Container maxW="full" id="pricing" p={0}>
        <SFCCTA />
        <PricingTier onOpen={onOpen} onOpenSub={onOpenSub} />
      </Container>
      <SmallFooterWithNavigation />
    </VStack>
  );
}
