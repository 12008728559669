import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
  Tag,
} from '@chakra-ui/react';
import { IoPlayCircleOutline } from 'react-icons/io5';

export default function CallToActionWithVideo({ onOpen }) {
  return (
    <Container maxW={'7xl'}>
      <Box px={8} pt={12} pb={24} mx="auto">
        <Box
          w={{
            base: 'full',
            md: 11 / 12,
            xl: 9 / 12,
          }}
          mx="auto"
          textAlign={{
            base: 'left',
            md: 'center',
          }}
        >
          <Box pb={10}>
            <Tag variant="outline" size="lg" rounded="xl" colorScheme="blue">
              <Flex>
                <Box
                  p={{ base: 1, sm: 0 }}
                  color="black"
                  _dark={{ color: 'white' }}
                >
                  🔷
                </Box>

                <Box
                  p={{ base: 1, sm: 0 }}
                  color="black"
                  _dark={{ color: 'white' }}
                  fontWeight="semibold"
                  fontFamily="Inter"
                >
                  {' '}
                  Sign up and Download Semble for free!
                </Box>
              </Flex>
            </Tag>
          </Box>
          <Text
            mb={6}
            fontSize={{
              base: '4xl',
              md: '6xl',
            }}
            fontWeight="semibold"
            fontFamily="Inter"
            lineHeight="none"
            letterSpacing={{
              base: 'normal',
              md: 'tight',
            }}
            color="gray.900"
            _dark={{
              color: 'gray.100',
            }}
          >
            Track and organize all your{' '}
            <Text
              display={{
                base: 'block',
                lg: 'inline',
              }}
              w="full"
              bgClip="text"
              bgGradient="linear(to-r, blue.500, pink.500)"
              fontWeight="extrabold"
            >
              crafting supplies
            </Text>{' '}
            all in one spot.
          </Text>
          <Text
            px={{
              base: 0,
              lg: 24,
            }}
            mb={6}
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color="gray.700"
            _dark={{
              color: 'gray.300',
            }}
            fontWeight="normal"
            fontFamily="Inter"
          >
            Semble is the easiest way to track and organize your inventory of
            art and crafting materials, saving time to put back into creating
            amazing projects.
          </Text>
          <Stack
            direction={{
              base: 'column',
              sm: 'row',
            }}
            mb={{
              base: 4,
              md: 8,
            }}
            spacing={2}
            justifyContent={{
              sm: 'left',
              md: 'center',
            }}
          >
            <Button
              as="a"
              colorScheme="gray"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              fontWeight="semibold"
              fontFamily="Inter"
              size="lg"
              cursor="pointer"
              href={'#pricing'}
            >
              Get the Free App
              <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                  clipRule="evenodd"
                />
              </Icon>
            </Button>
            <Button
              as="a"
              variant="solid"
              colorScheme="blue"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{
                base: 'full',
                sm: 'auto',
              }}
              mb={{
                base: 2,
                sm: 0,
              }}
              size="lg"
              fontWeight="semibold"
              fontFamily="Inter"
              cursor="pointer"
              href={'#pricing'}
            >
              Subscribe and Save
              <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </Icon>
            </Button>
          </Stack>
        </Box>
        <Box
          position={'relative'}
          w={{
            base: 'full',
            md: 10 / 12,
          }}
          h="fit-content"
          mx="auto"
          mt={20}
          textAlign="center"
        >
          <Image
            w="full"
            rounded="lg"
            shadow="2xl"
            src="/mockup1.png"
            alt="Semble feedback boards software screenshot"
          />
          <Box
            position={'absolute'}
            top={0}
            left={0}
            w="100%"
            h="100%"
            bg="blackAlpha.500"
            rounded="lg"
          />
          <IconButton
            aria-label={'Play Button'}
            _hover={{ bg: 'transparent' }}
            icon={<Icon as={IoPlayCircleOutline} boxSize={6} />}
            size="lg"
            position={'absolute'}
            left={'50%'}
            top={'50%'}
            transform={'translateX(-50%) translateY(-50%)'}
            onClick={onOpen}
          />
        </Box>
      </Box>
    </Container>
  );
}

export const Blob = props => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
