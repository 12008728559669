import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import {
  FcAbout,
  FcAcceptDatabase,
  FcAlphabeticalSortingAz,
  FcAssistant,
  FcCollaboration,
  FcDocument,
  FcDonate,
  FcManager,
} from 'react-icons/fc';

const cards = [
  {
    icon: FcAcceptDatabase,
    title: 'Effortless Inventory Tracking',
    desc: `Easily monitor your entire collection of creative supplies, from stamps and dies to inks and pens. Keep track of what you have, what you need, and where it is.`,
  },
  {
    icon: FcAlphabeticalSortingAz,
    title: 'Centralized Organization',
    desc: `Enjoy the convenience of having all your creative resources in one spot. Customize categories, tags, and labels for your supplies, ensuring easy accessibility and efficient organization.`,
  },
  {
    icon: FcDocument,
    title: 'Seamless Project Planning',
    desc: `With Semble, you can plan your projects effortlessly. Seamlessly transition from the planning phase to actual creation, with all your supplies at your fingertips.`,
  },
  {
    icon: FcCollaboration,
    title: 'Creative Community',
    desc: `Join a vibrant community of like-minded creators. Share your projects, receive feedback, and discover new techniques and inspiration together. Take creativity to new heights with Semble.`,
  },
];

const Card = ({ heading, description, icon, href }) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md" fontWeight="semibold" fontFamily="Inter">
            {heading}
          </Heading>
          <Text mt={1} fontSize={'sm'} fontWeight="semibold" fontFamily="Inter">
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default function FeatureCards() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading
          fontSize={{ base: '2xl', sm: '4xl' }}
          fontWeight="bold"
          fontFamily="Inter"
        >
          Packed In, Ready to Roll
        </Heading>
        <Box>
          <Box maxW="sm" mx="auto">
            <Text
              color={'gray.600'}
              _dark={{ color: 'gray.300' }}
              fontSize={{ base: 'sm', sm: 'lg' }}
              fontWeight="semibold"
              fontFamily="Inter"
            >
              Semble is more than just a tool for saving time; it's a
              comprehensive platform designed to elevate and enhance your
              creative journey.
            </Text>
          </Box>
        </Box>
      </Stack>

      <Container maxW={'4xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {cards.map((e, i) => (
            <Box key={i}>
              <Card
                heading={e.title}
                icon={<Icon as={e.icon} w={10} h={10} />}
                description={e.desc}
                href={'#'}
              />
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  );
}
