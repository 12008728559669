import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  chakra,
} from '@chakra-ui/react';

const isSFCProject = false;
const freeMessage = `Semble will be included with your SearchFindCreate subscription`;

export default function SFCCTA() {
  return (
    <>
      {isSFCProject && (
        <Box
          w="full"
          bg="gray.50"
          _dark={{
            bg: 'gray.800',
          }}
        >
          <Box
            maxW="7xl"
            w={{
              md: '3xl',
              lg: '4xl',
            }}
            mx="auto"
            py={{
              base: 12,
              lg: 16,
            }}
            px={{
              base: 4,
              lg: 8,
            }}
            display={{
              lg: 'flex',
            }}
            alignItems={{
              lg: 'center',
            }}
            justifyContent={{
              lg: 'space-between',
            }}
          >
            <chakra.h2
              fontSize={{
                base: '3xl',
                sm: '4xl',
              }}
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              color="gray.900"
              _dark={{
                color: 'gray.100',
              }}
            >
              <chakra.span display="block">Already enjoying</chakra.span>
              <chakra.span
                display="block"
                color="brand.600"
                _dark={{
                  color: 'gray.500',
                }}
              >
                SearchFindCreate?
              </chakra.span>
            </chakra.h2>
            <Stack
              direction={{
                base: 'column',
                sm: 'row',
              }}
              mt={{
                base: 8,
                lg: 0,
              }}
              flexShrink={{
                lg: 0,
              }}
            >
              <FreeModal />
            </Stack>
          </Box>
        </Box>
      )}
    </>
  );
}

function FreeModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Link
        w={['full', , 'auto']}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        px={5}
        py={3}
        border="solid transparent"
        fontWeight="bold"
        rounded="md"
        shadow="md"
        _light={{
          color: 'white',
        }}
        bg="blue.600"
        _dark={{
          bg: 'blue.500',
        }}
        _hover={{
          bg: 'blue.700',
          _dark: {
            bg: 'blue.600',
          },
        }}
        onClick={onOpen}
      >
        Get Semble Free
      </Link>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Get Semble Free</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{freeMessage}</ModalBody>

          <ModalFooter>
            {/* <Button variant="ghost">Secondary Action</Button> */}
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
