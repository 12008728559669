import React from 'react';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';

import { Analytics } from '@vercel/analytics/react';

import Lander from './Lander';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl" w="100vw" overflowX="hidden">
        <Lander />
      </Box>
      <Analytics />
    </ChakraProvider>
  );
}

export default App;
